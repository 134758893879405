<template>
  <b-container>
    <b-form class="myOrganisation" @submit="saveOrganisation">
      <b-card
        bg-variant="light"
        class="mb-5"
        footer-class="text-right"
        footer-bg-variant="light"
        :title="$t('organisation.companyInfos')"
      >
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-companyName"
          :label="$t('organisation.companyName')"
        >
          <b-form-input
            id="organisation-companyName"
            v-model="organisation.companyName"
            :placeholder="$t('organisation.companyName')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-companyManagementFullnames"
          :label="$t('organisation.companyManagementFullnames')"
        >
          <b-form-input
            id="organisation-companyManagementFullnames"
            v-model="organisation.companyManagementFullnames"
            placeholder="Jane Doe & John Smith"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-postalAddress"
          :label="$t('organisation.postalAddress')"
        >
          <b-form-input
            id="organisation-postalAddress"
            v-model="organisation.postalAddress"
            :placeholder="$t('organisation.postalAddress')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-zipCode"
          :label="$t('organisation.zipCode')"
        >
          <b-form-input
            id="organisation-zipCode"
            v-model="organisation.zipCode"
            :placeholder="$t('organisation.zipCode')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-city"
          :label="$t('organisation.city')"
        >
          <b-form-input id="organisation-city" v-model="organisation.city" :placeholder="$t('organisation.city')">
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-website"
          :label="$t('organisation.website')"
        >
          <b-form-input
            id="organisation-website"
            v-model="organisation.website"
            :placeholder="$t('organisation.website')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-contactEmail"
          :label="$t('organisation.contactEmail')"
        >
          <b-form-input
            id="organisation-contactEmail"
            type="email"
            v-model="organisation.contactEmail"
            :placeholder="$t('organisation.contactEmail')"
          >
          </b-form-input>
        </b-form-group>
        <template #footer>
          <b-button variant="primary" @click="saveOrganisation">{{ $t('general.save') }}</b-button>
        </template>
      </b-card>

      <b-card
        bg-variant="light"
        class="mb-5"
        footer-class="text-right"
        footer-bg-variant="light"
        :title="$t('organisation.taxInfos')"
      >
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-taxNumber"
          :label="$t('organisation.taxNumber')"
        >
          <b-form-input
            id="organisation-taxNumber"
            v-model="organisation.taxNumber"
            :placeholder="$t('organisation.taxNumber')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-vatIdNumber"
          :label="$t('organisation.vatIdNumber')"
        >
          <b-form-input
            id="organisation-vatIdNumber"
            v-model="organisation.vatIdNumber"
            :placeholder="$t('organisation.vatIdNumber')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-bank"
          :label="$t('organisation.bank')"
        >
          <b-form-input id="organisation-bank" v-model="organisation.bank" :placeholder="$t('organisation.bank')">
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-bankId"
          :label="$t('organisation.bankId')"
        >
          <b-form-input id="organisation-bankId" v-model="organisation.bankId" :placeholder="$t('organisation.bankId')">
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-accountId"
          :label="$t('organisation.accountId')"
        >
          <b-form-input
            id="organisation-accountId"
            v-model="organisation.accountId"
            :placeholder="$t('organisation.accountId')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-iban"
          :label="$t('organisation.iban')"
        >
          <b-form-input id="organisation-iban" v-model="organisation.iban" :placeholder="$t('organisation.iban')">
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-bic"
          :label="$t('organisation.bic')"
        >
          <b-form-input id="organisation-bic" v-model="organisation.bic" :placeholder="$t('organisation.bic')">
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-defaultEmailDomains"
          :label="$t('organisation.defaultEmailDomains')"
        >
          <b-form-input
            id="organisation-defaultEmailDomains"
            v-model="organisation.defaultEmailDomains"
            placeholder="@test.com"
          >
          </b-form-input>
        </b-form-group>
        <template #footer>
          <b-button variant="primary" @click="saveOrganisation">{{ $t('general.save') }}</b-button>
        </template>
      </b-card>

      <b-card
        bg-variant="light"
        class="mb-5"
        footer-class="text-right"
        footer-bg-variant="light"
        :title="$t('organisation.invoiceInfos')"
      >
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-invoiceManagementEnabled"
          :label="$t('organisation.invoiceManagementEnabled')"
        >
          <b-form-checkbox
            v-model="organisation.invoiceManagementEnabled"
            name="organisation-invoiceManagementEnabled"
            switch
            class="pt-2"
          >
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-invoiceSenderFullname"
          :label="$t('organisation.invoiceSenderFullname')"
        >
          <b-form-input
            id="organisation-invoiceSenderFullname"
            v-model="organisation.invoiceSenderFullname"
            :disabled="!organisation.invoiceManagementEnabled"
            :placeholder="$t('organisation.invoiceSenderFullname')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-currentInvoiceId"
          :label="$t('organisation.currentInvoiceId')"
        >
          <b-form-input
            id="organisation-currentInvoiceId"
            type="number"
            v-model="organisation.currentInvoiceId"
            :disabled="!organisation.invoiceManagementEnabled"
            :placeholder="$t('organisation.currentInvoiceId')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-defaultInvoiceNamePattern"
          :label="$t('organisation.defaultInvoiceNamePattern')"
        >
          <b-form-input
            id="organisation-defaultInvoiceNamePattern"
            v-model="organisation.defaultInvoiceNamePattern"
            :disabled="!organisation.invoiceManagementEnabled"
            :placeholder="$t('organisation.defaultInvoiceNamePattern')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-defaultInvoiceVatRateInPercents"
          :label="$t('organisation.defaultInvoiceVatRateInPercents')"
        >
          <b-form-input
            id="organisation-defaultInvoiceVatRateInPercents"
            type="number"
            v-model="organisation.defaultInvoiceVatRateInPercents"
            :disabled="!organisation.invoiceManagementEnabled"
            :placeholder="$t('organisation.defaultInvoiceVatRateInPercents')"
          >
          </b-form-input>
        </b-form-group>
        <template #footer>
          <b-button variant="primary" @click="saveOrganisation">{{ $t('general.save') }}</b-button>
        </template>
      </b-card>

      <b-card
        bg-variant="light"
        class="mb-5"
        footer-class="text-right"
        footer-bg-variant="light"
        :title="$t('organisation.personalInfos')"
      >
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-vacationManagementEnabled"
          :label="$t('organisation.vacationManagementEnabled')"
        >
          <b-form-checkbox
            v-model="organisation.vacationManagementEnabled"
            name="organisation-vacationManagementEnabled"
            switch
            class="pt-2"
          >
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-defaultVacationEntitlementInDaysPerYear"
          :label="$t('organisation.defaultVacationEntitlementInDaysPerYear')"
        >
          <b-form-input
            id="organisation-defaultVacationEntitlementInDaysPerYear"
            type="number"
            v-model="organisation.defaultVacationEntitlementInDaysPerYear"
            :disabled="!organisation.vacationManagementEnabled"
            :placeholder="$t('organisation.defaultVacationEntitlementInDaysPerYear')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-defaultWorkHoursPerWeek"
          :label="$t('organisation.defaultWorkHoursPerWeek')"
        >
          <b-form-input
            id="organisation-defaultWorkHoursPerWeek"
            type="number"
            v-model="organisation.defaultWorkHoursPerWeek"
            :disabled="!organisation.vacationManagementEnabled"
            :placeholder="$t('organisation.defaultWorkHoursPerWeek')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-defaultFederalState"
          :label="$t('organisation.defaultFederalState')"
        >
          <b-form-select
            id="organisation-defaultFederalState"
            :options="federalStateOptions"
            v-model="organisation.defaultFederalState"
            :disabled="!organisation.vacationManagementEnabled"
          >
            <template #first>
              <b-form-select-option :value="null" disabled selected>{{
                $t('federalState.choose')
              }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <template #footer>
          <b-button variant="primary" @click="saveOrganisation">{{ $t('general.save') }}</b-button>
        </template>
      </b-card>

      <b-card
        bg-variant="light"
        class="mb-5"
        footer-class="text-right"
        footer-bg-variant="light"
        :title="$t('organisation.legal')"
      >
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-imprint"
          :label="$t('organisation.imprint')"
        >
          <b-form-textarea
            id="organisation-imprint"
            rows="6"
            v-model="organisation.imprint"
            :placeholder="$t('organisation.imprint')"
          >
          </b-form-textarea>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-privacyPolicies"
          :label="$t('organisation.privacyPolicies')"
        >
          <b-form-textarea
            id="organisation-termsAndConditions"
            rows="12"
            v-model="organisation.privacyPolicies"
            :placeholder="$t('organisation.privacyPolicies')"
          >
          </b-form-textarea>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-termsAndConditions"
          :label="$t('organisation.termsAndConditions')"
        >
          <b-form-textarea
            id="organisation-termsAndConditions"
            rows="12"
            v-model="organisation.termsAndConditions"
            :placeholder="$t('organisation.termsAndConditions')"
          >
          </b-form-textarea>
        </b-form-group>

        <b-form-group
          class="mt-4 mb-2"
          label-cols="4"
          label-cols-lg="3"
          label-for="organisation-personalInfos"
          label=""
        >
        </b-form-group>
        <template #footer>
          <b-button variant="primary" @click="saveOrganisation">{{ $t('general.save') }}</b-button>
        </template>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'

export default {
  name: 'ManageOrganisation',
  mixins: [ApiMixin, NotificationMixin],
  data() {
    return {
      loading: false,
      organisation: {},
      federalStateOptions: StaticSelectOpts.federalStateOptions
    }
  },
  mounted() {
    this.loadOrganisation()
  },
  methods: {
    saveOrganisation() {
      let self = this
      this.postRequest(
        '/organisations',
        this.organisation,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('organisation.success-msg'))
          self.loadPublicOrganisation()
        })
      )
    },
    loadOrganisation() {
      let self = this
      this.getRequest(
        '/organisations',
        new RequestConfig().onSuccess(res => {
          self.organisation = res.data
        })
      )
    }
  }
}
</script>
