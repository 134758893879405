import { i18n } from '@/plugins/i18n'

export const StaticSelectOpts = {
  federalStateOptions: [
    { value: 'bw', text: i18n.t('federalState.bw') },
    { value: 'by', text: i18n.t('federalState.by') },
    { value: 'be', text: i18n.t('federalState.be') },
    { value: 'bb', text: i18n.t('federalState.bb') },
    { value: 'hb', text: i18n.t('federalState.hb') },
    { value: 'hh', text: i18n.t('federalState.hh') },
    { value: 'he', text: i18n.t('federalState.he') },
    { value: 'mv', text: i18n.t('federalState.mv') },
    { value: 'ni', text: i18n.t('federalState.ni') },
    { value: 'nw', text: i18n.t('federalState.nw') },
    { value: 'rp', text: i18n.t('federalState.rp') },
    { value: 'sl', text: i18n.t('federalState.sl') },
    { value: 'sn', text: i18n.t('federalState.sn') },
    { value: 'st', text: i18n.t('federalState.st') },
    { value: 'sh', text: i18n.t('federalState.sh') },
    { value: 'th', text: i18n.t('federalState.th') }
  ],
  userStatusOptions: [
    { value: 'ACTIVE', text: i18n.t('user.status.active') },
    { value: 'INACTIVE', text: i18n.t('user.status.inactive') }
  ],
  userRoleOptions: [
    { value: 'USER', text: i18n.t('user.roles.user') },
    { value: 'PROJECT_MANAGER', text: i18n.t('user.roles.project_manager') },
    { value: 'ADMIN', text: i18n.t('user.roles.admin') }
  ],
  projectStatusOptions: [
    { value: 'PLANNED', text: i18n.t('project.status.planned') },
    { value: 'OPEN', text: i18n.t('project.status.open') },
    { value: 'FINISHED', text: i18n.t('project.status.finished') },
    { value: 'CANCELLED', text: i18n.t('project.status.cancelled') }
  ],
  ticketStatusOptions: [
    { value: 'OPEN', text: i18n.t('ticket.status.open') },
    { value: 'FINISHED', text: i18n.t('ticket.status.finished') },
    { value: 'CANCELLED', text: i18n.t('ticket.status.cancelled') }
  ],
  absenceTypeOptions: [
    { value: 'PAID_VACATION', text: i18n.t('absence.types.paid_vacation') },
    { value: 'FLEXTIME', text: i18n.t('absence.types.flextime') },
    { value: 'SICK_LEAVE', text: i18n.t('absence.types.sick_leave') },
    { value: 'CHILD_SICK_LEAVE', text: i18n.t('absence.types.child_sick_leave') },
    { value: 'PARENTAL_LEAVE', text: i18n.t('absence.types.parental_leave') },
    { value: 'UNPAID_VACATION', text: i18n.t('absence.types.unpaid_vacation') },
    { value: 'SPECIAL_LEAVE', text: i18n.t('absence.types.special_leave') }
  ],
  absenceStatusOptions: [
    { value: 'CREATED', text: i18n.t('vacation.created') },
    { value: 'REVIEW', text: i18n.t('vacation.review') },
    { value: 'ACCEPTED', text: i18n.t('vacation.accepted') },
    { value: 'REJECTED', text: i18n.t('vacation.rejected') }
  ],
  healthInsuranceStatusOptions: [
    { value: null, text: i18n.t('absence.insurance-status-null') },
    { value: 'SUBMITTED', text: i18n.t('absence.insurance-status-submitted') },
    { value: 'BILLED', text: i18n.t('absence.insurance-status-billed') }
  ],
  timeTrackingUnitStatusOptions: [
    { value: 'RUNNING', text: i18n.t('timetracking.status.running') },
    { value: 'FINISHED', text: i18n.t('timetracking.status.finished') }
  ],
  reviewStatusOptions: [
    { value: null, text: 'UNREVIEWED' },
    { value: 'REVIEWED', text: 'REVIEWED' },
    { value: 'HIGHLIGHTED', text: 'HIGHLIGHTED' },
    { value: 'BILLED', text: 'BILLED' }
  ]
}
